import { Component, OnInit, Input, Inject } from "@angular/core";
import { ExtendedKpoService, UserService } from "../../../../../../api";
import { AlertService } from "../../../../services/alert.service";
import { Router } from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CookieService } from "ngx-cookie-service";
import { RoleService } from "../../../../services/role.service";

@Component({
  selector: "app-headquarter-list",
  templateUrl: "./headquarter-list.component.html",
  styleUrls: ["./headquarter-list.component.scss"],
})
export class HeadquarterListComponent implements OnInit {
  displayedColumns: string[] = ["name", "radio"];
  dataSource = null;
  locations = [];

  @Input() headquarters = [];

  constructor(private extendedKpoService: ExtendedKpoService, private cookieService: CookieService, private roleService: RoleService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private router: Router, private alertService: AlertService, private userService: UserService) { }

  ngOnInit() {
    this.dataSource = this.headquarters;
  }

  select(element) {
    console.log("headquater selected");
    if (element.disctict === undefined && element.street === undefined) {
      this.storage.set("place", "(Brak adresu)");
    } else {
      this.storage.set("place", element.district + " ul. " + element.street + " " + element.buildingNumber + (element.localNumber != null ? "/" + element.localNumber : ""));
    }
    this.extendedKpoService.getCompanyToken(element.eupId).subscribe((nres) => {
      const expiryTime = Date.now() + 1000 * 60 * 60 * 3;
      this.cookieService.set("token-expiry", expiryTime.toString(), 1, "/");
      this.cookieService.set("token-expiry", expiryTime.toString(), { expires: 1, sameSite: "None", secure: true });

      this.cookieService.set("token", nres, 1, "/");
      this.cookieService.set("token", nres, { expires: 1, sameSite: "None", secure: true });
      this.cookieService.set("eupId", element.eupId, 1, "/");
      this.cookieService.set("eupId", element.eupId, { expires: 1, sameSite: "None", secure: true });
      this.storage.set("eupId", element.eupId);
      this.userService.getCurrentUser().subscribe(user => {
        if (user.group.name === "Użytkownik") {

          this.roleService.setRole("SENDER");
          this.router.navigateByUrl("/");
          return
        }

        this.router.navigateByUrl("/role-selection");
      });

    });
  }
}
