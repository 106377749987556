<mat-dialog-content>
  <mat-horizontal-stepper linear #stepper>
    <mat-step [editable]="true">
      <ng-template matStepLabel>Przekazujący</ng-template>
      <h2 fxHide.gt-sm>Przekazujący</h2>
      <mat-spinner *ngIf="senderCompany == undefined || senderCompanyEup == undefined" style="margin: 0 auto"> </mat-spinner>
      <div *ngIf="senderCompany != undefined && senderCompanyEup != undefined" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="1%">
        <h3 fxFlex="100%" class="margin-top-zero">Informacje o podmiocie</h3>
        <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%">
          <mat-label>Numer rejestrowy</mat-label>
          <input matInput autocomplete="off" readonly [value]="senderCompany.registrationNumber" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%" *ngIf="senderCompany.name !== undefined">
          <mat-label>Nazwa</mat-label>
          <input matInput autocomplete="off" readonly [value]="senderCompany.name" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="senderCompany.nip !== undefined">
          <mat-label>NIP</mat-label>
          <input matInput autocomplete="off" readonly [value]="senderCompany.nip" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngFor="let item of senderCompany.address?.split(', ')">
          <mat-label>{{ item.split(": ")[0] }}</mat-label>
          <input matInput autocomplete="off" readonly [value]="item.split(': ')[1] === '' || item.split(': ')[1] === undefined ? '(brak danych)' : item.split(': ')[1]" />
        </mat-form-field>

        <h3 fxFlex="100%">Miejsce prowadzenia działalności</h3>

        <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="senderCompanyEup.registrationNumber !== undefined">
          <mat-label>Numer miejsca</mat-label>
          <input matInput autocomplete="off" readonly [value]="senderCompanyEup.registrationNumber" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%" *ngIf="senderCompanyEup.name !== undefined">
          <mat-label>Nazwa</mat-label>
          <input matInput autocomplete="off" readonly [value]="senderCompanyEup.name" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngFor="let item of senderCompanyEup.address?.split(', ')">
          <mat-label>{{ item.split(": ")[0] }}</mat-label>
          <input matInput autocomplete="off" readonly [value]="item.split(': ')[1] === '' || item.split(': ')[1] === undefined ? '(brak danych)' : item.split(': ')[1]" />
        </mat-form-field>

        <div fxFlex="100%"></div>
        <div class="checkbox-field" fxFlex="100%">
          <mat-checkbox [(ngModel)]="details.isWasteGenerating" name="isWasteGenerating">Wytwarzanie odpadów - w wyniku świadczenia usług (w rozumieniu art. 3 ust. 1 pkt 32 ustawy o odpadach) i/lub działalności w zakresie obiektów liniowych (w rozumieniu art. 3 pkt 3a ustawy - Prawo budowlane) </mat-checkbox>
        </div>
        <mat-form-field appearance="outline" fxFlex="100%">
          <mat-label>Miejsce wytwarzania odpadów (gmina)</mat-label>
          <!-- <input type="text" placeholder="Wpisz lub wybierz" [readonly]="!details.isWasteGenerating"
                        aria-label="Miejsce wytwarzania odpadów" matInput
                        (focusout)="wasteGeneratedTerytPkFocusout($event)" [formControl]="wasteGeneratedTerytPk"
                        [matAutocomplete]="auto" name="wasteGeneratedTerytPk"> -->

          <textarea
            matInput
            placeholder="Wpisz lub wybierz"
            [readonly]="!details.isWasteGenerating"
            aria-label="Miejsce wytwarzania odpadów"
            matInput
            (focusout)="wasteGeneratedTerytPkFocusout($event)"
            [formControl]="wasteGeneratedTerytPk"
            [matAutocomplete]="auto"
            name="wasteGeneratedTerytPk"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
          ></textarea>

          <mat-error *ngIf="wasteGeneratedTerytPk.invalid">Niewłaściwe miejsce wytwarzania odpadu</mat-error>
          <mat-autocomplete autoActiveFirstOption (optionSelected)="wasteGeneratedTerytPkActivated($event)" #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredWasteGeneratedTerytPks | async" [value]="option.fulltext">
              {{ option.fulltext }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="100%">
          <mat-label>Dodatkowe informacje o miejscu wytwarzania odpadów</mat-label>
          <input matInput autocomplete="off" name="wasteGeneratingAdditionalInfo" [(ngModel)]="details.wasteGeneratingAdditionalInfo" [readonly]="!details.isWasteGenerating" />
        </mat-form-field>
        <!-- <mat-spinner *ngIf="details == undefined" style="margin: 0 auto;"></mat-spinner>
            <div *ngIf="details != undefined" fxFlex="100%" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="1%">

                <h3 fxFlex="100%">Miejsce wytwarzania odpadów</h3>
                <mat-form-field appearance="outline" style="width: 100%;"
                    *ngIf="details.wasteGeneratedTeryt !== undefined">
                    <mat-label>Miejsce wytwarzania odpadów</mat-label>

                    <input matInput autocomplete="off" *ngIf="!details.isWasteGenerating" readonly
                        [value]="'zgodnie ze wskazanym powyżej miejscem prowadzenia działalności'" />
                    <input matInput autocomplete="off" *ngIf="details.isWasteGenerating" readonly [value]="details.wasteGeneratedTeryt" />
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 100%;"
                    *ngIf="details.isWasteGenerating && details.wasteGeneratingAdditionalInfo != null">
                    <mat-label>Dodatkowe informacje</mat-label>
                    <input matInput autocomplete="off" readonly [value]="details.wasteGeneratingAdditionalInfo" />
                </mat-form-field>
            </div> -->
      </div>
      <button mat-button color="primary" matStepperNext class="next-button" [disabled]="senderCompany == undefined || senderCompanyEup == undefined">Potwierdź</button>

      <a mat-button color="secondary" class="next-button" (click)="showHeadquarterSelection()" [disabled]="senderCompany == undefined || senderCompanyEup == undefined">Zmień siedzibę</a>
    </mat-step>

    <mat-step [editable]="true" *ngIf="role == 'CARRIER'; else carrierPicker">
      <ng-template matStepLabel>Transportujący</ng-template>
      <h2 fxHide.gt-sm>Transportujący</h2>
      <mat-spinner *ngIf="carrierCompany == undefined || carrierCompanyEup == undefined" style="margin: 0 auto"> </mat-spinner>
      <div *ngIf="carrierCompany != undefined && carrierCompanyEup != undefined" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="1%">
        <h3 fxFlex="100%" class="margin-top-zero">Informacje o podmiocie</h3>
        <mat-form-field style="width: 100%" *ngIf="carrierCompanyEx">
          <mat-select [(ngModel)]="carrierCompanyEx" name="carrierCompanyEx" (ngModelChange)="carrierCompanyExChanged()">
            <mat-option *ngFor="let company of carrierAdditionalCompanies" [value]="company">
              {{ company.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngIf="carrierCompany.registrationNumber !== undefined">
          <mat-label>Numer rejestrowy</mat-label>
          <input matInput autocomplete="off" readonly [value]="carrierCompany.registrationNumber" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%" *ngIf="carrierCompany.name !== undefined">
          <mat-label>Nazwa</mat-label>
          <input matInput autocomplete="off" readonly [value]="carrierCompany.name" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="24" fxFlex.lt-md="100%" *ngIf="carrierCompany.nip !== undefined">
          <mat-label>NIP</mat-label>
          <input matInput autocomplete="off" readonly [value]="carrierCompany.nip" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="24%" fxFlex.lt-md="100%" *ngFor="let item of carrierCompany.address?.split(', ')">
          <mat-label>{{ item.split(": ")[0] }}</mat-label>
          <input matInput autocomplete="off" readonly [value]="item.split(': ')[1] === '' || item.split(': ')[1] === undefined ? '(brak danych)' : item.split(': ')[1]" />
        </mat-form-field>

        <!-- <h3 fxFlex="100%">Miejsce prowadzenia działalności</h3>

            <mat-form-field appearance="outline" fxFlex="24%"
                *ngIf="carrierCompanyEup.registrationNumber !== undefined">
                <mat-label>Numer miejsca</mat-label>
                <input matInput autocomplete="off" readonly [value]="carrierCompanyEup.registrationNumber" />
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="74%" *ngIf="carrierCompanyEup.name !== undefined">
                <mat-label>Nazwa</mat-label>
                <input matInput autocomplete="off" readonly [value]="carrierCompanyEup.name" />
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="24%"
                *ngFor="let item of carrierCompanyEup.address?.split(', ')">
                <mat-label>{{item.split(': ')[0]}}</mat-label>
                <input matInput autocomplete="off" readonly
                    [value]="(item.split(': ')[1] === '' || item.split(': ')[1] === undefined ? '(brak danych)' : item.split(': ')[1])" />
            </mat-form-field> -->
      </div>
      <button mat-button color="primary" matStepperNext class="next-button" [disabled]="carrierCompany == undefined || carrierCompanyEup == undefined">Potwierdź</button>
      <button mat-button color="secondary" class="next-button" matStepperPrevious>Wstecz</button>
    </mat-step>
    <ng-template #carrierPicker>
      <mat-step [editable]="true">
        <ng-template matStepLabel>Transportujący</ng-template>
        <h2 fxHide.gt-sm>Transportujący</h2>

        <app-company-picker (companySelected)="setCarrierCompany($event)"></app-company-picker>
        <button mat-button color="primary" matStepperNext class="next-button" [disabled]="carrierCompany == undefined">Potwierdź</button>
        <button mat-button color="secondary" class="next-button" matStepperPrevious>Wstecz</button>
      </mat-step>
    </ng-template>
    <mat-step [editable]="true">
      <ng-template matStepLabel>Przejmujący</ng-template>
      <h2 fxHide.gt-sm>Przejmujący</h2>

      <app-company-picker (companySelected)="setCompany($event)"></app-company-picker>
      <button mat-button color="primary" matStepperNext class="next-button" [disabled]="receiverCompany == undefined">Potwierdź</button>
      <button mat-button color="secondary" class="next-button" matStepperPrevious>Wstecz</button>
    </mat-step>
    <mat-step [editable]="true">
      <ng-template matStepLabel>Siedziba przejmującego</ng-template>
      <h2 fxHide.gt-sm>Siedziba przejmującego</h2>

      <app-headquarter-picker [company]="receiverCompany" (headquarterSelected)="setEup($event)"> </app-headquarter-picker>
      <button mat-button color="primary" matStepperNext class="next-button" [disabled]="receiverCompanyEup == undefined">Potwierdź</button>
      <button mat-button color="secondary" class="next-button" matStepperPrevious>Wstecz</button>
    </mat-step>
    <mat-step [editable]="true">
      <ng-template matStepLabel>Dane szczegółowe</ng-template>
      <h2 fxHide.gt-sm>Dane szczegółowe</h2>

      <form class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%" fxLayoutGap.lt-md="1%" #form="ngForm" autocomplete="off">
        <mat-form-field appearance="outline" fxFlex="100%">
          <mat-label>Kod odpadów</mat-label>
          <input autocomplete="off" type="text" placeholder="Wpisz lub wybierz" aria-label="Kod odpadów" matInput (focusout)="wasteCodeFocusout($event)" [formControl]="wasteCode" [matAutocomplete]="auto" name="wasteCode" required />
          <mat-error *ngIf="wasteCode.invalid">Niewłaściwy kod odpadu</mat-error>
          <mat-autocomplete autoActiveFirstOption (optionSelected)="wasteCodeActivated($event)" #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredWasteCodes | async" [value]="option.code"> {{ option.code }} - {{ option.description }} </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <!-- <mat-form-field appearance="outline" fxFlex="24%">
                <mat-label>Kod odpadów</mat-label>
                <input matInput autocomplete="off" [value]="details.wasteCode" />
            </mat-form-field> -->

        <!-- <mat-form-field appearance="outline" fxFlex="66%">
                    <mat-label>Opis odpadów</mat-label>
                    <input matInput autocomplete="off" placeholder="Wpisz lub wybierz kod odpadu" readonly
                        [(ngModel)]="wasteCodeDescription" name="wasteCodeDescription" />
                </mat-form-field> -->

        <mat-form-field appearance="outline" fxFlex="32%" fxFlex.lt-md="100%">
          <mat-label>Rodzaj procesu przetwarzania</mat-label>
          <input autocomplete="off" type="text" placeholder="Wpisz lub wybierz" aria-label="Rodzaj procesu przetwarzania" matInput (focusout)="wasteProcessFocusout($event)" [formControl]="wasteProcess" [matAutocomplete]="auto2" name="wasteProcess" />
          <mat-error *ngIf="wasteProcess.invalid">Niewłaściwy rodzaj procesu przetwarzania</mat-error>
          <mat-autocomplete autoActiveFirstOption (optionSelected)="wasteProcessActivated($event)" #auto2="matAutocomplete">
            <mat-option *ngFor="let option of filteredWasteProcesses | async" [value]="option.codeName">
              {{ option.codeName + " - " + option.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="66%" fxFlex.lt-md="100%">
          <mat-label>Opis procesu przetwarzania</mat-label>
          <input matInput autocomplete="off" placeholder="Wpisz lub wybierz kod procesu przetwarzania" readonly [(ngModel)]="wasteProcessDescription" name="wasteProcessDescription" />
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="32%" fxFlex.lt-md="100%">
          <mat-label>Masa odpadów [Tony]</mat-label>
          <input matInput autocomplete="off" [(ngModel)]="wasteMassValue" (focusout)="parseWasteMass($event)" placeholder="1,0000" name="wasteMass" required />
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="32%" fxFlex.lt-md="100%" (click)="picker.open()">
          <mat-label>Planowana data transportu</mat-label>
          <input readonly matInput [(ngModel)]="plannedDate" [matDatepicker]="picker" name="date" required />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="32%" fxFlex.lt-md="100%">
          <mat-label>Planowana godzina transportu</mat-label>

          <input ngxTimepicker #t #time="ngModel" id="timepicker" mode="24h" [(ngModel)]="plannedTime" cancelLabel="Anuluj" placeholder="Wybierz godzinę" name="time" required (timeChange)="timeChangeHandler($event)" readonly />

          <mat-icon matSuffix (click)="t.showDialog()">access_time</mat-icon>

          <mat-error *ngIf="time.touched && time.invalid">niepoprawna godzina</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="39%" fxFlex.lt-md="100%" *ngIf="role == 'CARRIER'">
          <mat-label>Kierowca</mat-label>
          <mat-select [(value)]="driver" name="driver">
            <mat-option *ngFor="let driver of drivers" [value]="driver"> {{ driver.lastName }} {{ driver.firstName }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="32%" fxFlex.lt-md="100%" *ngIf="role == 'SENDER'">
          <mat-label>Numer rejestracyjny</mat-label>
          <input matInput [(ngModel)]="details.vehicleRegNumber" name="vehicleRegNumber" />
        </mat-form-field>

        <mat-form-field appearance="outline" [fxFlex]="role == 'CARRIER' ? '59%' : '100%'" fxFlex.lt-md="100%">
          <mat-label>Numer certyfikatu oraz numer pojemników</mat-label>
          <input matInput autocomplete="off" [(ngModel)]="details.certificateNumberAndBoxNumbers" name="certificateNumberAndBoxNumbers" />
        </mat-form-field>

        <div class="checkbox-field" fxFlex="24%" fxFlex.lt-md="100%">
          <mat-checkbox [(ngModel)]="details.wasteCodeExtended" name="wasteCodeExtended">Kod EX</mat-checkbox>
        </div>

        <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%">
          <mat-label *ngIf="details.wasteCodeExtended"> Rodzaj odpadu ex </mat-label>
          <input matInput autocomplete="off" [readOnly]="!details.wasteCodeExtended" (focusin)="details.wasteCodeExtended = true" [(ngModel)]="details.wasteCodeExtendedDescription" name="wasteCodeExtendedDescription" />
        </mat-form-field>

        <div class="checkbox-field" fxFlex="24%" fxFlex.lt-md="100%">
          <mat-checkbox [(ngModel)]="details.hazardousWasteReclassification" name="hazardousWasteReclassification">Inne niż niebezp. </mat-checkbox>
        </div>

        <mat-form-field appearance="outline" fxFlex="74%" fxFlex.lt-md="100%">
          <mat-label *ngIf="details.hazardousWasteReclassification"> Rodzaj odpadu</mat-label>
          <input matInput autocomplete="off" [readonly]="!details.hazardousWasteReclassification" [(ngModel)]="details.hazardousWasteReclassificationDescription" name="hazardousWasteReclassificationDescription" (focusin)="details.hazardousWasteReclassification = true" />
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Dodatkowe informacje</mat-label>
          <input matInput autocomplete="off" name="additionalInfo" [(ngModel)]="details.additionalInfo" />
        </mat-form-field>
        <div style="width: 100%">
          <button mat-button color="primary" type="submit" class="next-button" [disabled]="addingCard" (click)="addCard(form)">Dodaj kartę jako zatwierdzoną</button>
          <button mat-button color="primary" type="submit" class="next-button" [disabled]="addingCard" (click)="addPlannedCard(form)" *ngIf="role == 'SENDER'">Dodaj kartę jako planowaną</button>
          <button mat-button color="secondary" class="next-button" matStepperPrevious>Wstecz</button>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</mat-dialog-content>
