import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService, MessageType } from '../../../../services/alert.service';
import { ExtendedKpoService, SubscriptionPlan, SubscriptionPlanService, SubscriptionService, WasteRegisterPublicApiApiModelsElasticsearchCompanyEs } from '../../../../../../api';

@Component({
  selector: 'app-new-subscription-dialog',
  templateUrl: './new-subscription-dialog.component.html',
  styleUrls: ['./new-subscription-dialog.component.scss']
})
export class NewSubscriptionDialogComponent implements OnInit {
  details = {};
  plans: SubscriptionPlan[];
  selectedPlan: SubscriptionPlan;

  company: WasteRegisterPublicApiApiModelsElasticsearchCompanyEs;
  eups: any;

  address: string = "";

  constructor(public dialogRef: MatDialogRef<NewSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private subscriptionPlanService: SubscriptionPlanService,
    private subscriptionService: SubscriptionService,
    private extendedKpoService: ExtendedKpoService) { }


  ngOnInit(): void {
    this.subscriptionPlanService.getLatestSubscriptionPlan().subscribe(r => {
      this.plans = r;
      console.log(r);
    });
    this.extendedKpoService.getSelfCompany().subscribe(result => this.company = result);
    this.extendedKpoService.getCompanyHeadquarters().subscribe(result => this.eups = result);
  }

  planChanged(event) {

  }

  save() {
    this.dialogRef.close();
    this.subscriptionService.addSubscription(this.selectedPlan.id).subscribe(() => {
      this.alertService.showMessage("Zatwierdzono subskrypcję", MessageType.Info);
    });
  }
}
