import { Overlay } from "@angular/cdk/overlay";
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { Observable, Subject, Subscription } from "rxjs";
import { debounceTime, map, take, tap } from "rxjs/operators";
import { DraftCardDto, UserService, WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto, WasteTransferCardDto, WasteTransferCardService } from "../../../../../api";
import { DraftCard } from "../../../../../api/model/draftCard";
import { AlertService, MessageType } from "../../../services/alert.service";
import { CardService } from "../../../services/card.service";
import { MessagingService } from "../../../services/messaging.service";
import { BdoFilterComponent } from "../../bdo-filter/bdo-filter.component";
import { KpoDetailsDialogComponent } from "../archive/archive-details-dialog/kpo-details-dialog.component";
import { NewKpoDialogComponent } from "../home/kpo/new-kpo-dialog/new-kpo-dialog.component";
import { RejectCardDialogComponent } from "../home/kpo/reject-card-dialog/reject-card-dialog.component";
import { SelectReceiverDialogComponent } from "../home/kpo/select-receiver-dialog/select-receiver-dialog.component";
import { ViewDraftCardDialogComponent } from "../home/kpo/view-draft-card-dialog/view-draft-card-dialog.component";
import { SelectDriverDialogComponent } from "../realising-now/select-driver-dialog/select-driver-dialog.component";
import { FilingConfirmationDialogComponent } from "../../dialogs/filing/filing-confirmation-dialog/filing-confirmation-dialog.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { FiledCardDetailsComponent } from "../../dialogs/filed-card-details/filed-card-details.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

type KpoCard = WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto;

@UntilDestroy()
@Component({
  selector: "app-filing",
  templateUrl: "./filing.component.html",
  styleUrls: ["./filing.component.scss"],
})
export class FilingComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = true;
  error = false;

  reloadRequest: Subject<void> = new Subject();
  refreshSubscription = new Subscription();
  requestSubscription = new Subscription();
  scrollStrategy = this.overlay.scrollStrategies.block();
  currentPageSize = 0;

  filteredResults = [];
  filteredResults$: Observable<any[]> = new Observable((observer) => {
    this.paginator.page.pipe(untilDestroyed(this)).subscribe((p) => {
      this.load(observer);
    });
    this.load(observer);
    this.handleQueryChanged(observer);
    this.handleRefresh(observer);
    this.reloadRequest.pipe(untilDestroyed(this)).subscribe(() => this.load(observer));
  });

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(BdoFilterComponent, { static: true })
  bdoFilter: BdoFilterComponent;

  constructor(public cardService: CardService, private alertService: AlertService, private messagingService: MessagingService, public dialog: MatDialog, private overlay: Overlay, private wasteTransferCardService: WasteTransferCardService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private userService: UserService) { }

  ngAfterViewInit(): void {
    this.currentPageSize = this.paginator.pageSize;
    this.filteredResults$.subscribe((filteredResults) => (this.filteredResults = filteredResults));
  }
  ngOnInit(): void {
    let currentDate = new Date();
    let startDate = new Date(currentDate.valueOf() - 1000 * 60 * 60 * 24 * 14);
    this.bdoFilter.advancedQuery.transportDateRange.from = startDate;
    this.bdoFilter.advancedQuery.transportDateRange.to = currentDate;
  }

  load(observer) {
    this.error = false;
    this.loading = true;

    observer.next([]);

    let skip = this.paginator.pageIndex * this.paginator.pageSize;
    let take = this.paginator.pageSize;

    this.wasteTransferCardService
      .getFilteredWasteTransferCards(
        {
          cardNumber: this.bdoFilter.advancedQuery.cardNumber,
          cardStatusCodeName: this.bdoFilter.advancedQuery.cardStatusCodeName,
          startTransportDate: this.bdoFilter.advancedQuery.transportDateRange.from,
          endTransportDate: this.bdoFilter.advancedQuery.transportDateRange.to,
          registrationNumber: this.bdoFilter.advancedQuery.vehicleRegistrationNumber,
          senderName: this.bdoFilter.advancedQuery.senderName,
          wasteCode: this.bdoFilter.advancedQuery.wasteCode,
          skip: skip,
          take: take,
        },
        undefined,
        false,
        this.storage.get("eupId")
      )
      .subscribe(
        (result) => {
          observer.next(result.items);
          this.paginator.length = result.totalItemCount;
          this.loading = false;
          this.error = false;
        },
        () => {
          this.error = true;
          this.loading = false;
          this.alertService.showMessage("Wystąpił błąd podczas ładowania listy", MessageType.Error);
        }
      );
  }

  handleRefresh(observer) {
    this.refreshSubscription.add(
      this.messagingService.messageReceived$.pipe(untilDestroyed(this)).subscribe((payload) => {
        this.alertService.showMessage(payload.data.body.replace("\r\n", " | "), MessageType.Notification);
        this.load(observer);
      })
    );
  }

  handleQueryChanged(observer) {
    this.requestSubscription.add(
      this.bdoFilter.advancedQueryTextChanged.pipe(debounceTime(300)).subscribe(() => {
        this.load(observer);
      })
    );
    this.requestSubscription.add(
      this.bdoFilter.advancedQueryChanged.subscribe(() => {
        this.load(observer);
      })
    );
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
    this.requestSubscription.unsubscribe();
  }

  @HostListener("window:focus", ["$event"])
  onFocus(event: FocusEvent): void {
    this.reloadRequest.next();
  }

  openDetailsDialog(card): void {
    this.dialog.open(FiledCardDetailsComponent, {
      width: "950px",
      data: { cardId: card.id },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
  }

  openFilingConfirmationDialog(): void {
    //let companyId = this.storage.get("CompanyId");
    let isSender = false;
    let selectedCards = this.filteredResults.filter((r) => r.checked == true);
    console.log(selectedCards);
    this.userService.getCurrentUser().subscribe(u => { if (u.group.name == "Użytkownik") isSender = true })
    selectedCards.forEach((card) => {
      if (card.senderEupId != undefined) isSender = true;
    });
    const dialogRef = this.dialog.open(FilingConfirmationDialogComponent, {
      width: "750px",
      data: {
        selectedCards: selectedCards.map((s) => s.id),
        isSender: isSender,
      },
      scrollStrategy: this.scrollStrategy,
      closeOnNavigation: true,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.reloadRequest.next();
    });
  }

  selectAll() {
    this.filteredResults.forEach((element) => {
      element.checked = true;
    });
  }
}
