import { Injectable, NgZone } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  public messageReceived$: Observable<any>;

  constructor(private angularFireMessaging: AngularFireMessaging, private ngZone: NgZone) {
    this.messageReceived$ = new Observable((observer) => {
      this.angularFireMessaging.onMessage((msg) => {
        this.ngZone.run(() => observer.next(msg));
      });
    }).pipe(catchError((err) => of(err)));

    //this.angularFireMessaging.on
  }
}
