<mat-spinner *ngIf="loading" style="margin: 90px auto"></mat-spinner>

<div [ngStyle]="{ display: loading ? 'none' : 'inherit' }">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Plan abonamentowy</mat-card-title>
            <mat-card-subtitle>Informacje o planie abonamentowym oraz możliwości zmiany planu</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-spinner *ngIf="loading" style="margin: 0 auto"></mat-spinner>
            <div *ngIf="!loading" class="container" fxLayout="wrap row" fxLayoutAlign="left" fxLayoutGap="1%">
                <mat-form-field appearance="standard" fxFlex="19%">
                    <mat-label>Nazwa planu abonamentowego</mat-label>
                    <input matInput autocomplete="off" readonly [value]="subscription?.subscriptionPlan.name  == undefined ? '(Brak)' : subscription?.subscriptionPlan.name" />
                </mat-form-field>
                <mat-form-field appearance="standard" fxFlex="19%">
                    <mat-label>Termin ważności planu abonamentowego</mat-label>
                    <input matInput autocomplete="off" readonly [value]="subscription?.dueDate | date : 'dd.MM.yyyy'" />
                </mat-form-field>
                <mat-form-field appearance="standard" fxFlex="19%">
                    <mat-label>Limit wpisów w planie abonamentowym</mat-label>
                    <input matInput autocomplete="off" readonly [value]="subscription?.subscriptionPlan.filingCardsAmount == undefined ? 'nieograniczony' : subscription?.subscriptionPlan.filingCardsAmount" />
                </mat-form-field>
                <mat-form-field appearance="standard" fxFlex="19%">
                    <mat-label>Aktualnie wykorzystany limit wpisów</mat-label>
                    <input matInput autocomplete="off" readonly [value]="subscription?.filingCardCount == undefined ? 0 : subscription?.filingCardCount">
                </mat-form-field>
                <mat-form-field appearance="standard" fxFlex="19%">
                    <mat-label>Status</mat-label>
                    <input matInput autocomplete="off" readonly [value]="subscription?.isPaymentConfirmed == true ? 'Opłacony':'Nieopłacony' ">
                </mat-form-field>


            </div>
            <p>Poznaj dostępne plany abonamentowe i wybierz ten, który odpowiada Twoim potrzebom. </p>
            <a [routerLink]="['/payments']" routerLinkActive="router-link-active" style="font-weight: 500; cursor: pointer"> Przejdź do planów abonamentowych </a>
        </mat-card-content>
        <mat-card-actions>
            <button mat-flat-button color="primary" (click)="initPayment()" *ngIf="subscription?.subscriptionPlan.price != null && subscription?.isPaymentConfirmed == false" style="margin-right: 15px;"> Opłać </button>
            <button mat-flat-button color="primary" (click)="openNewSubscriptionDialog()"> Kup plan abonamentowy </button>
        </mat-card-actions>
    </mat-card>
</div>