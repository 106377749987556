import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, combineLatest, concat, of } from "rxjs";
import { Configuration, Permission, SubscriptionPlan, User, UserDto, UserService } from "../../../api";
import { catchError, filter, map, shareReplay, startWith, switchMap, tap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class CurrentUserService {
    hasSubscriptionPlanPermissions(requiredPermissions: SubscriptionPlan) {
        return this.currentUser$.pipe(filter(u => u != undefined), map(u => {
            if (u.activeSubscription == null) return false;
            if (requiredPermissions.senderModule == true && u.activeSubscription.subscriptionPlan.senderModule != true) return false;
            if (requiredPermissions.filingReportModule == true && u.activeSubscription.subscriptionPlan.filingReportModule != true) return false;
            if (requiredPermissions.yearlyReportModule == true && u.activeSubscription.subscriptionPlan.yearlyReportModule != true) return false;
            return true;
        }));
    }

    public currentUserChanged$ = new BehaviorSubject<string | null>(null);
    public currentUser$: Observable<UserDto>;

    constructor(userService: UserService, private configuration: Configuration) {
        this.currentUser$ = this.currentUserChanged$
            .pipe(
                startWith(undefined),
                tap(token => {
                    if (token)
                        this.configuration.apiKeys["Authorization"] = "Bearer " + token;
                }),
                switchMap(() =>
                    concat(
                        of(undefined),
                        userService.getCurrentUser().pipe(catchError(() => of(undefined)))
                    )
                ),
                shareReplay()
            );
    }

    public hasPermissions(permissions: Permission[]): Observable<boolean> {
        return this.currentUser$.pipe(
            map(u => u.group.permissions.filter(p => p.granted == true)),
            map(userPermissions => userPermissions
                .some(userPermission => permissions
                    .some(rp => rp.methodName == userPermission.methodName &&
                        rp.controllerName == userPermission.controllerName
                    )
                )
            )
        );
    }

    clearUser() {
        this.currentUserChanged$.next(null);
    }
}
