import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { AlertService, MessageType } from "../../../../services/alert.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../../../../api/api/authentication.service";
import { Configuration } from "../../../../../../api/configuration";
import { UserService } from "../../../../../../api";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EulaAcceptanceDialogComponent } from "../../../dialogs/eula-acceptance-dialog/eula-acceptance-dialog.component";
import { CookieService } from "ngx-cookie-service";
import { CurrentUserService } from "../../../../services/current-user.service";
import { filter, take } from "rxjs/operators";

@Component({
  selector: "app-internal-authentication",
  templateUrl: "./internal-authentication.component.html",
  styleUrls: ["./internal-authentication.component.scss"],
})
export class InternalAuthenticationComponent implements AfterViewInit {
  public hidePassword = true;

  @Output() authorized: EventEmitter<any> = new EventEmitter();
  @ViewChild("login") loginInput: ElementRef;
  @ViewChild("password") passwordInput: ElementRef;

  constructor(private currentUserService: CurrentUserService, private alertService: AlertService, private configuration: Configuration, private userService: UserService, private authenticationService: AuthenticationService, private dialog: MatDialog, private router: Router, private cookieService: CookieService) { }
  ngAfterViewInit(): void {
    this.loginInput.nativeElement.value = "";
  }

  validateForm($event) { }
  openEula() {
    let dialogRef: MatDialogRef<any>;

    dialogRef = this.dialog.open(EulaAcceptanceDialogComponent, {
      data: {},
      width: "500px",
      closeOnNavigation: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.userService.acceptEula().subscribe((r) => {
          this.authorized.emit(null);
        });
      } else {
        this.configuration.apiKeys["Authorization"] = null;
      }
    });
  }

  public authenticate(form) {
    this.authenticationService.authenticateInternalUser(form.value).subscribe(
      (next) => {
        this.currentUserService.clearUser();
        this.configuration.apiKeys["Authorization"] = "Bearer " + next;
        this.cookieService.set("bearer", next, 1, "/");
        this.cookieService.set("bearer", next, { expires: 1, sameSite: "None", secure: true });
        this.currentUserService.currentUserChanged$.next(next);
        this.currentUserService.currentUser$.pipe(filter(c => c != undefined), take(1)).subscribe((u) => {
          if (u.group.name === "Użytkownik") {
            this.authorized.emit(null);
          } else {
            if (u.eulaAccepted === false && u.group.name === "Transportujący") {
              this.openEula();
            } else {
              this.authorized.emit(null);
            }
          }
        });
      },
      (error) => {
        this.alertService.showMessage(error.error, MessageType.Error);
      }
    );
  }
}
