<h1 mat-dialog-title>Kup plan abonamentowy</h1>
<mat-dialog-content class="mat-typography">
    <mat-form-field style="width: 100%" appearance="outline">
        <mat-label>Plan</mat-label>
        <mat-select [(ngModel)]="selectedPlan" (selectionChange)="planChanged($event)">
            <mat-option *ngFor="let item of plans" [value]="item">{{ item.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 49%; margin-right: 1%;">
        <mat-label>Nazwa firmy</mat-label>
        <input matInput autocomplete="off" [value]="company?.name" />
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 49%; margin-right: 1%;">
        <mat-label>NIP</mat-label>
        <input matInput autocomplete="off" [value]="company?.nip" />
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 49%; margin-right: 1%;">
        <mat-label>Kod pocztowy</mat-label>
        <input matInput autocomplete="off" [value]="company?.postalCode" />
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 49%; margin-right: 1%;">
        <mat-label>Adres</mat-label>
        <input matInput autocomplete="off" [value]="address" />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="save()">Zatwierdź</button>
</mat-dialog-actions>