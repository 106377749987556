<div class="container">
  <mat-card class="login-card">
    <mat-card-header>
      <h5 class="mat-h1">Rejestracja</h5>
    </mat-card-header>
    <mat-card-content>
      <form #form="ngForm" (ngSubmit)="form.valid && onSubmit(form)" autocomplete="off">
        <div mat-dialog-content>

          <mat-form-field class="full-width" appearance="fill">
            <mat-label>imię</mat-label>
            <input matInput name="username" [(ngModel)]="user.firstName" required="true" />
          </mat-form-field>
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>nazwisko</mat-label>
            <input matInput name="username" [(ngModel)]="user.lastName" required="true" />
          </mat-form-field>
          <mat-form-field class="full-width" appearance="fill">
            <mat-label>e-mail</mat-label>
            <input matInput name="username" [(ngModel)]="user.username" required="true" />
          </mat-form-field>

          <mat-form-field class="full-width" appearance="fill" fxFlex="100%">
            <mat-label>Hasło</mat-label>
            <input matInput autocomplete="off" name="password" [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="password" [required]="true">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">
              {{hidePassword ? 'visibility' : 'visibility_off'}}
            </mat-icon>
          </mat-form-field>

          <div class="eula">
            Rejestrując się do serwisu BDOsystem danymi wygenerowanymi na podstawie Twojego
            wniosku o rejestrację podmiotu, złożonego na stronie www.bdosystem.pl,
            akceptujesz zapisy
            <a href="/assets/regulamin_serwisu_BDOsystem.pdf" target="_blank">Regulaminu serwisu</a>
            oraz
            <a href="https://bdosystem.pl/polityka-prywatnosci/" target="_blank">Polityki Prywatności</a>.
          </div>
        </div>
        <div mat-dialog-actions>
          <button mat-flat-button color="primary" type="submit">zarejestruj</button>
        </div>
      </form>
      <div class="footer" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="gappx">
        <a routerLink="/login" routerLinkActive="active">powrót do logowania</a>
      </div>
    </mat-card-content>
  </mat-card>
</div>