<div class="container">
  <mat-card class="login-card">
    <img mat-card-image src="/assets/logo.png" />
    <mat-card-content>
      <br />
      <app-internal-authentication (authorized)="authorized()"></app-internal-authentication>
      <!-- <p class="separator">lub</p>
      <app-google-auth-button></app-google-auth-button> -->
      <div class="footer" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="gappx">

        <a routerLink="/register" routerLinkActive="active" class="register-button" color="primary" style="color: #88c946">zarejestruj się</a>
        <a routerLink="/password-recovery-init" routerLinkActive="active">nie możesz się zalogować?</a>
      </div>
    </mat-card-content>
  </mat-card>
</div>