import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, GroupsService } from '../../../../../../api';
import { AlertService, MessageType } from '../../../../services/alert.service';

@Component({
  selector: 'app-password-recovery-init-step',
  templateUrl: './password-recovery-init-step.component.html',
  styleUrls: ['./password-recovery-init-step.component.scss']
})
export class PasswordRecoveryInitStepComponent implements OnInit {
  constructor(
    private userService: UserService,
    private groupService: GroupsService,
    private alertService: AlertService,
    private router: Router
  ) { }

  email: string;

  ngOnInit() { }

  onSubmit(form): void {
    this.userService.requestPasswordChange(this.email)
      .subscribe(
        (result: any) => {
          this.router.navigateByUrl("/password-recovery");
        },
        error => {
          this.alertService.showMessage(
            error.error,
            MessageType.Error
          );
        }
      );
  }
}
