import { Component, OnInit, Inject } from "@angular/core";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";
import { AlertService, MessageType } from "../../../../services/alert.service";
import {
  UsersService,
  GroupsService,
  GroupDto,
  UserDto,
} from "../../../../../../api";

@Component({
  selector: "app-user-wizard",
  templateUrl: "./user-wizard.component.html",
  styleUrls: ["./user-wizard.component.scss"],
})
export class UserWizardComponent implements OnInit {
  password: string;
  hidePassword = true;
  sendMail = false;

  userGroups: GroupDto[];
  user: UserDto = { firstName: "", lastName: "", username: "" };

  constructor(
    private userService: UsersService,
    private groupService: GroupsService,
    private alertService: AlertService,
    public dialog: MatDialogRef<UserWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.groupService.getAll().subscribe((value) => {
      this.userGroups = value;
    });
    this.user = this.data.user === undefined ? null : this.data.user;
  }

  valueChange($event) {
    this.sendMail = $event.checked;
  }

  onSubmit(form): void {
    switch (this.data.mode) {
      case "create":
        this.userService
          .add(
            this.user,
            this.password === "" ? null : this.password,
            this.sendMail
          )
          .subscribe(
            (result: any) => {
              this.alertService.showMessage(
                "Pomyślnie dodano użytkownika",
                MessageType.Info
              );
              this.dialog.close(true);
            },
            (error) =>
              this.alertService.showMessage(error.error, MessageType.Error)
          );
        break;
      case "edit":
        this.userService
          .update(
            this.user.id,
            this.user,
            this.password === "" ? null : this.password,
            this.sendMail
          )
          .subscribe(
            (result: any) => {
              this.alertService.showMessage(
                "Pomyślnie zaktualizowano dane użytkownika",
                MessageType.Info
              );
              this.dialog.close(true);
            },
            (error) =>
              this.alertService.showMessage(error.error, MessageType.Error)
          );
        break;
    }
  }
}
