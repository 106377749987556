<form #form="ngForm" (ngSubmit)="form.valid && authenticate(form)" class="form login-form">

    <mat-form-field class="full-width" appearance="fill">
        <mat-label>e-mail</mat-label>
        <input (change)="validateForm($event)" matInput #login placeholder="example@mae.pl" name="username" ngModel required>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="fill">
        <mat-label>hasło</mat-label>
        <input matInput name="password" [type]="hidePassword ? 'password' : 'text'" ngModel required>
        <mat-icon #password matSuffix (click)="hidePassword = !hidePassword">
            {{hidePassword ? 'visibility' : 'visibility_off'}}
        </mat-icon>
    </mat-form-field>
    <mat-dialog-actions>
        <button mat-raised-button color="primary" type="submit">zaloguj</button>

    </mat-dialog-actions>

</form>