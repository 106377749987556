<div class="container">
  <mat-card class="login-card">
    <img mat-card-image src="/assets/logo.png" />
    <mat-card-header>
      <mat-card-title> Wybór podmiotu </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-spinner *ngIf="loading" style="margin: 0 auto"></mat-spinner>
      <mat-form-field style="padding: 10px; margin-left: -10px;" class="month-select">
        <mat-label>Rola</mat-label>
        <mat-select [(ngModel)]="role" name="role" (selectionChange)="filter()">
          <mat-option [value]="'Transportujący'"> Transportujący </mat-option>
          <mat-option [value]="'Użytkownik'"> Użytkownik </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>Wyszukaj</mat-label>
        <input matInput autocomplete="off" [(ngModel)]="filterValue">
      </mat-form-field>
      <br>
      <app-subject-list *ngIf="!loading" [subjects]="(subjects | subjectFilter:filterValue)" (updated)="filter()">
      </app-subject-list>
    </mat-card-content>
  </mat-card>
</div>