import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

export enum MessageType {
  Error,
  Info,
  Warning,
  Notification,
}

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private snackBar: MatSnackBar) {}

  public showMessage(message: string, label: MessageType) {
    let messageLabel;
    switch (label) {
      case MessageType.Error:
        messageLabel = "Błąd";
        break;
      case MessageType.Info:
        messageLabel = "Informacja";
        break;
      case MessageType.Warning:
        messageLabel = "Ostrzeżenie";
        break;
      case MessageType.Notification:
        messageLabel = "Powiadomienie";
        break;
    }
    this.snackBar.open(message, messageLabel, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
}
