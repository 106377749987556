<div *ngIf="!(dataSource == null)">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="radio">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="text-align: right;">
        <button (click)="select(element)" color="primary" mat-button>Wybierz</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nazwa siedziby</th>
      <td mat-cell *matCellDef="let element" [class.inactive]="!element.isActive">
        <span style="font-weight: 500;">[{{element.identificationNumber}}]</span>
        <div *ngIf="element.addresHtml !== undefined" [innerHTML]="element.addressHtml"></div>
        <span *ngIf="element.addresHtml === undefined">
          {{element.name}}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>