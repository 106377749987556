import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AlertService, MessageType } from "./services/alert.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(public alertService: AlertService, private router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => {},
        (error) => {
          console.log(error);
          if (error.status === 403 || error.status === 401) {
            if (error.error == "USER_NOT_LOGGED_IN") {
              this.router.navigateByUrl("/login");
              return;
            }
            if (error.error == "BDO_TOKEN_MISSING") {
              this.router.navigateByUrl("/subject-selection");
              return;
            }
            this.alertService.showMessage("Brak uprawnień do wykonania tej czynności", MessageType.Error);
            return;
          }
          if (error instanceof HttpErrorResponse) {
            //this.alertService.showMessage("Błąd połączenia", MessageType.Error);
          } else {
            this.alertService.showMessage("Nieoczekiwany błąd", MessageType.Error);
          }
        }
      )
    );
  }
}
