import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { FormControl, Validators } from "@angular/forms";
import { SubjectAuthenticationService } from "../../../../../api/api/subjectAuthentication.service";
import { MatStepper } from "@angular/material/stepper";
import { MessagingService } from "../../../services/messaging.service";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { UserService } from "../../../../../api";
import { Subscription } from "rxjs";
import { CurrentUserService } from "../../../services/current-user.service";
import { filter, take } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private subjectAuthenticationService: SubjectAuthenticationService, private userService: UserService, private currentUserService: CurrentUserService, private angularFireMessaging: AngularFireMessaging) { }

  loading = true;
  message: any;

  ngOnInit() { }

  authorized() {
    this.angularFireMessaging.requestPermission.pipe(untilDestroyed(this)).subscribe(
      (result) => {
        this.angularFireMessaging.requestToken.pipe(untilDestroyed(this)).subscribe(
          (token) => {
            this.userService.addDeviceToken({ deviceToken: token }).subscribe(
              () => { },
              (error) => console.error(error.error)
            );
          },
          (error) => console.error("error while requesting token")
        );
      },
      (error) => console.error("error while requesting permissions")
    );
    this.currentUserService.currentUser$.pipe(untilDestroyed(this), filter(u => u != undefined), take(1)).subscribe(() => this.router.navigateByUrl("/subject-selection"));
  }
}
