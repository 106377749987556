<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="radio">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" style="text-align: right; min-width: 120px">
      <button (click)="select(element)" color="primary" mat-button>
        Wybierz
      </button>
      <button (click)="removeSubject(element)" color="warn" mat-button>
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Nazwa podmiotu</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="element.addresHtml !== undefined" [innerHTML]="element.addressHtml"></div>
      <span *ngIf="element.addresHtml === undefined">
        {{ element.name }}
      </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-card-actions>
  <button mat-button class="add-subject-button" style="color: #616876; font-weight: 400" (click)="openDialog()">
    dodaj nowy podmiot
  </button>
</mat-card-actions>