<h1 mat-dialog-title *ngIf="isSender">
  Jeśli na wskazanych kpo pełnisz rolę przekazującego i chcesz na podstawie tych kpo dodać również wpisy do ewidencji odpadów wytworzonych, określ:
</h1>
<h1 mat-dialog-title *ngIf="!isSender">
  Czy na pewno chcesz dodać wybrane karty do ewidencji?
</h1>
<div mat-dialog-content *ngIf="isSender">

  <p style="font-family: Roboto; margin-top: 40px;margin-left: 16px;
  margin-bottom: -6px;">- gdzie dany odpad został wytworzony: </p>
  <mat-radio-group aria-label="Wybierz" [(ngModel)]="isInInstallation">
    <mat-radio-button value="true">w związku z eksploatacją instalacji
      <mat-form-field style="width: 332px;">
        <mat-label>Nazwa instalacji (opcjonalne)</mat-label>
        <input matInput autocomplete="off" [value]="instalationName" />
      </mat-form-field>
    </mat-radio-button>
    <mat-radio-button value="false">poza instalacją</mat-radio-button>
  </mat-radio-group>
  <p style="font-family: Roboto; margin-top: 30px;margin-left: 16px;
  ">- datę wytworzenia odpadu względem daty zatwierdzenia kpo w bdo: <mat-form-field style="width: 100px;margin-left: 16px;">
      <mat-label>dzień/dni przed</mat-label>
      <input matInput autocomplete="off" [value]="days" type="number" min="0" (keydown)="validate($event)" />
    </mat-form-field></p>


</div>
<div mat-dialog-actions>
  <button mat-button style="margin-top: 15px" (click)="dialogRef.close(false)">
    Anuluj
  </button>
  <button mat-button style="margin-top: 15px" (click)="markForFiling(false)" color="warn" *ngIf="isSender">
    Pomiń
  </button>
  <button mat-button style="margin-top: 15px" (click)="markForFiling(true)" [disabled]="isInInstallation == undefined && isSender" color="primary">
    Zapisz
  </button>
</div>