import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";
import { debounceTime } from "rxjs-compat/operator/debounceTime";
import { WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto } from "../../../../api";
import { DraftCard } from "../../../../api/model/draftCard";
import { MatCheckboxChange } from "@angular/material/checkbox";
type KpoCard = WasteRegisterPublicApiApiModelsResponsesWasteRegisterWasteTransferCardV1KpoAsCarrierSearchResultDto;

@Component({
  selector: "app-card-block",
  templateUrl: "./card-block.component.html",
  styleUrls: ["./card-block.component.scss"],
})
export class CardBlockComponent implements OnInit {
  @Input() public details: KpoCard | DraftCard;
  @Input() public header: string;
  @Input() public value: string;
  @Input() public text: any;
  @Input() public radio = false;
  @Input() public check = false;
  @Input() public checkboxChecked;
  @Output() public cardSelected = new EventEmitter<any>();
  @Input() public expanded = true;
  @Output() public checked: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get kpoCard(): KpoCard {
    return this.details as KpoCard;
  }
  public get draftCard(): DraftCard {
    return this.details as DraftCard;
  }
  constructor() {}

  radioChanged($event: MatRadioChange) {
    if ($event.value) {
      this.cardSelected.emit(this.value);
    }
  }

  checkedChanged($event: MatCheckboxChange) {
    (this.details as any).checked = $event.checked;
    this.checked.emit($event.checked);
  }

  ngOnInit(): void {
    console.log(this.radio);
  }
}
