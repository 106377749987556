<mat-card>
    <mat-card-header>
        <mat-card-title>Płatności</mat-card-title>
        <mat-card-subtitle>Wykaz faktur i status płatności</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-spinner *ngIf="loading" style="margin: 0 auto"></mat-spinner>
        <ng-container *ngIf="paymentHistory">
            <mat-spinner *ngIf="loading; else view" style="margin: 90px auto"></mat-spinner>
            <ng-template #view>
                <ng-container *ngFor="let item of paymentHistory">
                    <mat-accordion>
                        <app-card-block [header]="'FV/BDO/2023/'+item.id">
                            <div fxLayout="row wrap">
                                <app-card-block-field header="Data wystawienia" *ngIf="item.createdAt" fxFlex="100%" fxFlex.gt-lg="50%">
                                    {{ item.createdAt| date : "dd.MM.yyyy" }}
                                </app-card-block-field>
                            </div>
                        </app-card-block>
                    </mat-accordion>
                </ng-container>
            </ng-template>
        </ng-container>
        <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
</mat-card>