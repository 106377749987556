import { Component, OnInit } from "@angular/core";
import {
  UsersService,
  GroupsService,
  UserDto,
  UserService
} from "../../../../../api";
import { AlertService, MessageType } from "../../../services/alert.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"]
})
export class RegistrationComponent implements OnInit {
  constructor(
    private userService: UserService,
    private groupService: GroupsService,
    private alertService: AlertService,
    private router: Router
  ) { }
  password: string;
  hidePassword = true;
  sendMail = false;
  user: UserDto = { firstName: "", lastName: "", username: "" };

  ngOnInit() { }

  onSubmit(form): void {
    this.userService
      .register(
        this.user,
        this.password,
      )
      .subscribe(
        (result: any) => {
          this.alertService.showMessage(
            "Pomyślnie zarejestrowano. Sprawdź swoją skrzynkę pocztową w celu zakończneia rejestracji.",
            MessageType.Info
          );
          this.router.navigate(["/login"])
        },
        (error) =>
          this.alertService.showMessage(error.error, MessageType.Error)
      );
  }
}
