import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";

import { AlertService, MessageType } from "../../../services/alert.service";
import { ExtendedTableComponent } from "../../../helpers/extended-management";

import { UserWizardComponent } from "./user-wizard/user-wizard.component";
import {
  UsersService,
  UserDto,
  SubjectDto,
  SubjectAuthenticationService,
} from "../../../../../api";
import { SubjectWizardComponent } from "./subject-wizard/subject-wizard.component";
import { ConfirmDialogComponent } from "../../dialogs/confirm-dialog/confirm-dialog.component";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent extends ExtendedTableComponent implements OnInit {
  displayedColumns = [
    "select",
    "name",
    "username",
    "group",
    "phoneNumber",
    "details",
  ];

  role: "user" | "carrier" | "administrator" | "driver" = "user";

  users: UserDto[];

  constructor(
    public datepipe: DatePipe,
    private alertService: AlertService,
    private userService: UsersService,
    private subjectAuthenticationService: SubjectAuthenticationService,
    private dialog: MatDialog
  ) {
    super();
  }

  openDialog(mode: string, element = {}) {
    const dialogRef = this.dialog.open(UserWizardComponent, {
      data: { mode: mode, user: element },
      width: "500px",
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  openSubjectWizardDialog(mode: string, element = {}) {
    const dialogRef = this.dialog.open(SubjectWizardComponent, {
      data: { mode: mode, user: element },
      width: "500px",
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.reloadData();
      }
    });
  }

  deleteSelected() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: { message: "Czy na pewno chcesz usunąć ten podmiot?" },
      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selection.selected.forEach((val, index, array) => {
          this.userService._delete(val.id).subscribe(
            (next) => {
              this.alertService.showMessage(
                "Pomyślnie usunięto użytkownika",
                MessageType.Info
              );
              this.selection.clear();
              this.reloadData();
            },
            (error) => {
              this.alertService.showMessage(
                "Wystąpił błąd podczas usuwania użytkownika: " + error.error,
                MessageType.Error
              );
            }
          );
        });
      }
    });
  }

  reloadData() {
    this.loading = true;

    this.userService.getAll().subscribe((result: UserDto[]) => {
      switch (this.role) {
        case "user":
          this.updateTableData(
            result.filter((u) => u.group?.name === "Użytkownik")
          );
          break;
        case "carrier":
          this.updateTableData(
            result.filter(
              (u) =>
                u.group?.name === "Transportujący" ||
                u.group?.name === "Dyspozytor"
            )
          );

          break;
        case "driver":
          this.updateTableData(
            result.filter((u) => u.group?.name === "Kierowca")
          );

          break;
        case "administrator":
          this.updateTableData(
            result.filter((u) => u.group?.name === "Administrator")
          );

          break;
      }
    });
  }

  applySelect() {
    switch (this.role) {
      case "user":
        this.displayedColumns = [
          "select",
          "name",
          "username",
          "creationDate",
          "activationDate",
          "integrationDate",
          "details",
        ];
        break;
      case "carrier":
        this.displayedColumns = [
          "select",
          "name",
          "username",
          "creationDate",
          "group",
          "integrationDate",
          "phoneNumber",
          "details",
        ];
        break;
      case "driver":
        this.displayedColumns = [
          "select",
          "subject",
          "name",
          "username",
          "creationDate",
          "details",
        ];
        break;
      case "administrator":
        this.displayedColumns = [
          "select",
          "name",
          "username",
          "creationDate",
          "details",
        ];
        break;
    }
    this.reloadData();
  }

  exportData() {
    const data = JSON.stringify(this.dataSource.data, null, 2);
    const blob: Blob = new Blob([data], { type: "text/json" });
    const fileName = "export.json";
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement(
      "a"
    ) as HTMLAnchorElement;
    a.href = objectUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(objectUrl);
  }
  ngOnInit() {
    this.applySelect();
  }
}
