import { Pipe, PipeTransform } from "@angular/core";
import { SubjectDto } from "../../../../../api";

@Pipe({ name: "subjectFilter" })
export class SubjectFilterPipe implements PipeTransform {
  transform(value: SubjectDto[], text?: string): SubjectDto[] {
    if (text === "") {
      return value;
    }
    return value.filter((s) =>
      s.name.toLocaleLowerCase().includes(text.toLocaleLowerCase())
    );
  }
}
