import { Component, OnInit, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { AlertService, MessageType } from "../../../services/alert.service";
import { ExtendedKpoService, UserService, WasteRegistryAuthService } from "../../../../../api";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { RoleService } from "../../../services/role.service";

@Component({
  selector: "app-headquarter-selection",
  templateUrl: "./headquarter-selection.component.html",
  styleUrls: ["./headquarter-selection.component.scss"],
})
export class HeadquarterSelectionComponent implements OnInit {
  constructor(private router: Router, private alertService: AlertService, private extendedKpoService: ExtendedKpoService, @Inject(LOCAL_STORAGE) private storage: WebStorageService, private auth: WasteRegistryAuthService, private userService: UserService, private roleService: RoleService) { }

  headquarters = [];
  loading = true;
  subjectName: string;

  ngOnInit(): void {
    this.subjectName = this.storage.get("SubjectName");
    this.auth.getEups().subscribe(
      (result) => {
        console.log(result);
        this.headquarters = result.items.reverse();
        this.loading = false;
      },
      (error) => {
        this.alertService.showMessage("Klucz API jest niepoprawny.", MessageType.Error);
        this.router.navigateByUrl("/login");
      }
    );
  }

  selected() {
    this.userService.getCurrentUser().subscribe(s => {
      if (s.group.name == "Użytkownik") {
        this.roleService.setRole("SENDER");
        this.router.navigateByUrl("/");
        return;
      }
      this.router.navigateByUrl("/role-selection");
      
    })
  
  }
}
