import { Component, OnInit } from '@angular/core';
import { NewSubscriptionDialogComponent } from './new-subscription-dialog/new-subscription-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentService, Subscription, SubscriptionService } from '../../../../../api';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  loading = false;

  subscription: Subscription;

  constructor(public dialog: MatDialog, private subscriptionsService: SubscriptionService, private paymentService: PaymentService) { }

  ngOnInit(): void {
    this.subscriptionsService.getLatestSubscription().subscribe(sub => this.subscription = sub);

  }
  openNewSubscriptionDialog(): void {
    const dialogRef = this.dialog.open(NewSubscriptionDialogComponent, {
      width: "550px",
      data: {},

      closeOnNavigation: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.subscriptionsService.getLatestSubscription().subscribe(sub => {
        this.subscription = sub;
        if (sub.subscriptionPlan.price != undefined && sub.subscriptionPlan.price != null) {
          this.initPayment();
        }
      });
    });
  }

  initPayment() {
    this.paymentService.initiatePayment(this.subscription.id).subscribe(res => { if (res.redirectUrl != null) { window.location.href = res.redirectUrl } });
  }

}
