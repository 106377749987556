export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './backup.service';
import { BackupService } from './backup.service';
export * from './cars.service';
import { CarsService } from './cars.service';
export * from './console.service';
import { ConsoleService } from './console.service';
export * from './draftCards.service';
import { DraftCardsService } from './draftCards.service';
export * from './driverApp.service';
import { DriverAppService } from './driverApp.service';
export * from './extendedKpo.service';
import { ExtendedKpoService } from './extendedKpo.service';
export * from './groups.service';
import { GroupsService } from './groups.service';
export * from './mobileApp.service';
import { MobileAppService } from './mobileApp.service';
export * from './payment.service';
import { PaymentService } from './payment.service';
export * from './plannedWasteCard.service';
import { PlannedWasteCardService } from './plannedWasteCard.service';
export * from './recordCard.service';
import { RecordCardService } from './recordCard.service';
export * from './serverManagement.service';
import { ServerManagementService } from './serverManagement.service';
export * from './subjectAuthentication.service';
import { SubjectAuthenticationService } from './subjectAuthentication.service';
export * from './subscription.service';
import { SubscriptionService } from './subscription.service';
export * from './subscriptionPlan.service';
import { SubscriptionPlanService } from './subscriptionPlan.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './version.service';
import { VersionService } from './version.service';
export * from './wasteRegistryAuth.service';
import { WasteRegistryAuthService } from './wasteRegistryAuth.service';
export * from './wasteTransferCard.service';
import { WasteTransferCardService } from './wasteTransferCard.service';
export const APIS = [AuthenticationService, BackupService, CarsService, ConsoleService, DraftCardsService, DriverAppService, ExtendedKpoService, GroupsService, MobileAppService, PaymentService, PlannedWasteCardService, RecordCardService, ServerManagementService, SubjectAuthenticationService, SubscriptionService, SubscriptionPlanService, UserService, UsersService, VersionService, WasteRegistryAuthService, WasteTransferCardService];
