import { Component, OnInit } from '@angular/core';
import { NewSubscriptionDialogComponent } from '../subscriptions/new-subscription-dialog/new-subscription-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Payment, PaymentService } from '../../../../../api';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  loading = false;
  paymentHistory: Payment[];
  constructor(public dialog: MatDialog, private paymentService: PaymentService) { }

  ngOnInit(): void {
    this.paymentService.getPaymentHistory().subscribe(ph => this.paymentHistory = ph);
  }
}
