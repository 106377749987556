<div fxLayout="row" class="navbar-container">
  <img mat-card-image src="/assets/images/bdo_system_kierowca_ikony_logo_duze.png" fxHide.gt-sm fxFlex="36px" />
  <div fxLayout="row" fxFill fxLayoutAlign.lt-md="center center" fxLayoutGap.lt-md="6px" class="button-container">
    <button class="headquarter-button" mat-button [matMenuTriggerFor]="menuFirst" #menuFirstTrigger="matMenuTrigger" fxFill.lt-md disableRipple>
      <mat-icon class="menu-icon">home</mat-icon>
      <span class="text">
        <span *ngIf="currentHeadquarter != undefined">{{ currentHeadquarter }}</span>
        <span *ngIf="role != undefined"> - {{ role == "CARRIER" ? "transportujący / łączący role" : "przekazujący" }}</span>
      </span>

      <mat-icon *ngIf="!menuFirstTrigger.menuOpen" class="arrow-down-icon">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="menuFirstTrigger.menuOpen" class="arrow-down-icon">keyboard_arrow_up</mat-icon>
    </button>

    <button #profileButton disableRipple #menuProfileTrigger="matMenuTrigger" class="user-menu-button" *ngIf="user != undefined" mat-button [matMenuTriggerFor]="menuSecond" aria-label="Example icon-button with a menu" fxFlex.lt-md="70px">
      <mat-icon class="menu-icon">account_circle</mat-icon>
      <span class="text">{{ user.firstName }} {{ user.lastName }}</span>
      <mat-icon *ngIf="!menuProfileTrigger.menuOpen" class="arrow-down-icon">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="menuProfileTrigger.menuOpen" class="arrow-down-icon">keyboard_arrow_up</mat-icon>
    </button>
  </div>
</div>
<mat-menu #menuFirst="matMenu">
  <a mat-menu-item style="width: 368px; background-color: #10172a; color: white" routerLink="/headquarter-selection" routerLinkActive="active">
    <mat-icon style="color: white">exit_to_app</mat-icon>
    <span>Zmień siedzibę</span>
  </a>

  <a mat-menu-item style="width: 368px; background-color: #10172a; color: white" routerLink="/subject-selection" routerLinkActive="active">
    <mat-icon style="color: white">exit_to_app</mat-icon>
    <span>Zmień podmiot</span>
  </a>

  <a mat-menu-item style="width: 368px; background-color: #10172a; color: white" (click)="openRoleChangeDialog()" *ngIf="!isUser">
    <mat-icon style="color: white">exit_to_app</mat-icon>
    <span>Zmień rolę</span>
  </a>
  <!-- <a mat-menu-item style="width: 368px; background-color: #10172a; color: white" routerLink="/role-selection" routerLinkActive="active" fxHide.gt-sm>
    <mat-icon style="color: white">exit_to_app</mat-icon>
    <span>Zmień rolę</span>
  </a> -->
</mat-menu>
<mat-menu class="profile-menu" #menuSecond="matMenu">
  <a mat-menu-item style="width: 218px; background-color: #10172a; color: white" (click)="openUserViewDialog()">
    <mat-icon style="color: white">account_box</mat-icon>
    <span fxHide.lt-md>Profil</span>
    <span fxHide.gt-sm>{{ user?.firstName }} {{ user?.lastName }}</span>
  </a>
  <a mat-menu-item style="width: 218px; background-color: #10172a; color: white" href="/assets/regulamin_serwisu_BDOsystem.pdf" target="_blank">
    <mat-icon style="color: white">picture_as_pdf</mat-icon>
    <span>Regulamin</span>
  </a>
  <a mat-menu-item style="width: 218px; background-color: #10172a; color: white" href="/assets/instrukcja_bdo_system.pdf" target="_blank">
    <mat-icon style="color: white">picture_as_pdf</mat-icon>
    <span>Instrukcja</span>
  </a>

  <a mat-menu-item style="width: 218px; background-color: #10172a; color: white" routerLink="/login" routerLinkActive="active">
    <mat-icon style="color: white">exit_to_app</mat-icon>
    <span>Wyloguj się</span>
  </a>
</mat-menu>